import '../assets/css/calculations.css'
import mail from '../assets/images/mail.svg'

function SignupSend() {
  return (
    <div>
      <div className={'Email-reply'}>
        <p className={'Er-top'}>When your CMS is deployed and ready to use we'll send you an email.
          Check your inbox, it usually takes about 15 minutes to deploy!</p>
          <img src ={mail} alt="Check your inbox." className={'mail-logo'}/>
      </div>
    </div>
  )
}

export default SignupSend
