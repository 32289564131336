import {createGlobalState} from 'react-hooks-global-state';
import {initializeApp} from "firebase/app"

// Import the functions you need from the SDKs you need
import { getFunctions } from 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBQ6o5Jkb9ep-93aryVtMpwRKVWCrer1Qc",
  authDomain: "roitool.firebaseapp.com",
  databaseURL: "https://roitool-default-rtdb.firebaseio.com",
  projectId: "roitool",
  storageBucket: "roitool.appspot.com",
  messagingSenderId: "84975679110",
  appId: "1:84975679110:web:bde0868c5467eb39a1c307",
  measurementId: "G-1WY2N1RKDS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)

const functions = getFunctions(app);

export {functions}

const {setGlobalState, useGlobalState} = createGlobalState({
    siteName: '',
    email: '',
});

export {useGlobalState,setGlobalState};