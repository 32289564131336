import SignupSend from './pages/SignupSend'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signup from './pages/Signup.jsx'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='Signup' element={<Signup/>}/>
        <Route path='Signup-send' element={<SignupSend/>}/>
      </Routes>
  </BrowserRouter>
  )
}

export default App