import { Link } from 'react-router-dom';
import { useState } from 'react';
import blackbutton from '../assets/images/BTN_Large_Lightbutton.svg';
import loadingSpinner from '../assets/images/3-dots-scale-middle.svg';
import mail from '../assets/images/mail.svg';
import { functions, setGlobalState, useGlobalState } from '../components/AppContext';
import '../assets/css/Signup.css';
import { httpsCallable, connectFunctionsEmulator } from "firebase/functions";

function Signup() {
  // connectFunctionsEmulator(functions, 'localhost', 5001);

  const [emailAddress] = useGlobalState("email");
  const [siteName] = useGlobalState("siteName");
  const [siteCreatedStatus,setSiteCreatedStatus] = useState();
  const [loading,setLoading] = useState(false);
  
  const inputsitenamevalue = (e) => {
    setGlobalState("siteName",e.target.value);
    e.preventDefault();
  }
  
  const inputvalue = (e) =>{
    setGlobalState("email",e.target.value);
    e.preventDefault();
  } 

  const createTenant = httpsCallable(functions, 'createTenant');
  const deployFreeTrial = httpsCallable(functions, 'deployFreeTrial');
  // const createStorageFolder = httpsCallable(functions, 'createStorageFolder');
    
  const setupFreeTrialForCustomer = async () => {
    setLoading(true);
    setGlobalState("email",emailAddress);

    // Add bildit- to all sites such that cors will work with whitelist that includes bildit-*
    let siteNameStripped = siteName.replace(/[^a-zA-Z0-9]/g, "");
    siteNameStripped = 'bildit-'.concat(siteNameStripped)
    setGlobalState("siteName",siteNameStripped);
    console.log(`siteNameStripped ${siteNameStripped}`)

    const createCustomerTenantResult = await createCustomerTenant(emailAddress,siteNameStripped);

    const tenantId = createCustomerTenantResult.data.tenantId;
    const userId = createCustomerTenantResult.data.userId;

    var request = {}
    request.siteName = siteNameStripped;
    request.email = emailAddress;
    request.tenantId = tenantId;
    request.userId = userId;
    request.dbUrl = 'https://'.concat(siteNameStripped).concat('.firebaseio.com');
    
    const deployFreeTrialResult = await deployFreeTrial(request);

    setSiteCreatedStatus(createCustomerTenantResult.data.code === 200 && deployFreeTrialResult.data.code === 200);
    
    if (createCustomerTenantResult.data.code !== 200) {
      alert("Other error creating site. " + JSON.stringify(createCustomerTenantResult));
      return { status: "Error", code: 500, message: "Other error creating site."};
    }

  }
 
  const validateAndCreateTenant = async (siteName, emailAddress) => {
    if(!siteName || siteName === ""){
      alert("Please enter a site name");
      return;
    }

    var request = {};
    request.siteName = siteName;
    request.email = emailAddress;

    const createTenantResult = await createTenant(request);

    return createTenantResult;
  }

  const createCustomerTenant = async (emailAddress,siteName) => {
    const createTenantStatus = await validateAndCreateTenant(siteName,emailAddress);

    if (createTenantStatus && createTenantStatus.code === 500) {
      console.log("Internal Server Error creating tenant.");
    }

    return createTenantStatus;
  }
  
  return (
    <div>
      <h1 className='Signup'>Start Your Free Trial</h1>
      <h3 className='Signup'>14 Days Free</h3>
      {!siteCreatedStatus && <div id="box">
        <div id="white">
            {!loading && <div id="signupForm">
              <span className="subtitle">Enter your email to get the login link</span>
              <input type="text" placeholder="Site Name" className='sitename .Signup' name="Site-Name" onChange={inputsitenamevalue} value={siteName}/>
              <div className='hoverme'>
                <input type="text" placeholder="Email Address" className='emailSignup' name="Email-Capture" onChange={inputvalue} value={emailAddress}/>
              </div>
              <Link><img src={blackbutton} className='blackbutton' onClick={setupFreeTrialForCustomer} alt="Submit" style={{height: '35px', width: '120px', marginTop: '15px'}} /></Link>
            </div>}
            {loading && <div id="loading">
              <div id="loadingSpinner"> 
                <img src={loadingSpinner} alt="Loading" />
              </div>
              <div className="break"></div>
              <div id="loadingSubtitle">We're building your own BILDIT CMS!</div>
            </div>}
          </div>
        </div>
      }
      {siteCreatedStatus && <div id="emailSent">
            <div className='Email-reply'>
              <p className='Er-top'>We're building your trial BILDIT Content Management System.<br /><br/>Check your inbox in the next 15 minutes for a link!</p>
              <img src ={mail} className='mail-logo' alt="Email"/>
            </div>
          </div>
        }
    </div>
  )
}

export default Signup
